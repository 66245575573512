@tailwind base;
@tailwind components;
@tailwind utilities;

.picItem form input[type="file"] {
  color: transparent;
}

.picItem form input[type="file"]::file-selector-button {
  visibility: hidden;
}

.picItem form input[type="file"]::before {
  content: "+";
  color: black;
  display: inline-block;
  background: white;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-user-select: none;
  cursor: pointer;
  padding: 50px 60px;
}
.saved {
  position: absolute;
  font-size: 36px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - (200px / 2));
  left: calc(50% - (100px / 2));
}
